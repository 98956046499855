import React from 'react';
import {
    Heading,
    Paragraph,
    Image,
    ButtonLink,
    Link,
    Spacer,
    Logo,
    Card,
    LanguagePicker,
    SignupForm,
    PricingModule,
    Video,
    Screenshot,
} from '@/components/blocks/parts';
import { graphql } from 'gatsby';
import { Row, Col } from 'react-bootstrap';

type GridColumnProps = {
    page: Queries.HygraphCMS_Page;
    gridColumn: Queries.HygraphCMS_GridColumn;
};

const getRowComponent = (
    row: Queries.HygraphCMS_GridColumnContentrowsUnion,
    page: Queries.HygraphCMS_Page,
) => {
    switch (row.component) {
        case 'Heading':
            const heading = row as Queries.HygraphCMS_Heading;
            return <Heading key={heading.id} {...heading} />;
        case 'Paragraph':
            const paragraph = row as Queries.HygraphCMS_Paragraph;
            return <Paragraph key={paragraph.id} {...paragraph} />;
        case 'Image':
            const image = row as Queries.HygraphCMS_Image;
            return <Image key={image.id} image={image} />;
        case 'Video':
            const video = row as Queries.HygraphCMS_Video;
            return <Video key={video.id} {...video} />;
        case 'ButtonLink':
            const buttonLink = row as Queries.HygraphCMS_ButtonLink;
            return <ButtonLink key={buttonLink.id} {...buttonLink} />;
        case 'Link':
            const link = row as Queries.HygraphCMS_Link;
            return <Link key={link.id} {...link} />;
        case 'Spacer':
            const spacer = row as Queries.HygraphCMS_Spacer;
            return <Spacer key={spacer.id} {...spacer} />;
        case 'Logo':
            const logo = row as Queries.HygraphCMS_Logo;
            return <Logo key={logo.id} {...logo} />;
        case 'Card':
            const card = row as Queries.HygraphCMS_Card;
            return <Card key={card.id} card={card} page={page} />;
        case 'LanguagePicker':
            return <LanguagePicker key={row.id} page={page} />;
        case 'SignupForm':
            const form = row as Queries.HygraphCMS_SignupForm;
            return <SignupForm key={row.id} form={form} page={page} />;
        case 'Screenshot':
            const screenshot = row as Queries.HygraphCMS_Screenshot;
            return <Screenshot key={row.id} screenshot={screenshot} />;
        case 'PricingModule':
            const pricingModule = row as Queries.HygraphCMS_PricingModule;
            return (
                <PricingModule
                    key={row.id}
                    pricingModule={pricingModule}
                    page={page}
                />
            );
        default:
            return null;
    }
};

export const GridColumn = ({
    gridColumn: { gridColumnContent },
    page,
}: GridColumnProps) => {
    if (!gridColumnContent?.rows.length) return null;

    return (
        <>
            {gridColumnContent.rows.map((row) => (
                <Row key={row.id}>
                    <Col>{getRowComponent(row, page)}</Col>
                </Row>
            ))}
        </>
    );
};

// We needed to recast the remoteId as id in order to embed GridColumnContent on Richtext Help articles. This change does not affect GridColumnContent in other pages.
export const gridColumnFragment = graphql`
    fragment HygraphCMS_GridColumnContent on HygraphCMS_GridColumnContent {
        id: remoteId
        rows {
            ...HygraphCMS_ButtonLink
            ...HygraphCMS_Card
            ...HygraphCMS_Heading
            ...HygraphCMS_Image
            ...HygraphCMS_LanguagePicker
            ...HygraphCMS_Link
            ...HygraphCMS_Logo
            ...HygraphCMS_Video
            ...HygraphCMS_Paragraph
            ...HygraphCMS_PricingModule
            ...HygraphCMS_SignupForm
            ...HygraphCMS_Spacer
            ...HygraphCMS_Screenshot
        }
    }
    fragment HygraphCMS_GridColumn on HygraphCMS_GridColumn {
        id
        component
        alignSelf
        alignContent
        gridColumnContent {
            ...HygraphCMS_GridColumnContent
        }
    }
`;
